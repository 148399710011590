<template lang="html">

   <div id="parentx">
    <vs-sidebar v-if="$parent.$parent.user" default-index="1" :parent="parent" :hiddenBackground="doNotClose" color="primary" class="sidebarx" spacer v-model="isSidebarActive" :click-not-close="doNotClose" >
        <div class="header-sidebar text-center" slot="header">
            <!--<vs-avatar size="70px" :src="require('@/assets/images/users/2.jpg')"/>-->
            <h4 v-if="$parent.$parent.user">{{$parent.$parent.user.username}}</h4>
            <!--<small>jeckson@gmail.com</small>-->
        </div>
        <template v-for="(sidebarLink, index) in sidebarLinks" >

            <vs-sidebar-item v-if="!(sidebarLink.permission < $parent.$parent.user.permission)" :icon="sidebarLink.icon" :to="sidebarLink.url" :key="`sidebarLink-${index}`" :index="index">
              <span class="hide-in-minisidebar">{{ sidebarLink.name }}</span>
            </vs-sidebar-item>
        </template>
        <template>
            <vs-sidebar-item icon="face" @click="$parent.$parent.logout()">
              <span class="hide-in-minisidebar">Logout</span>
            </vs-sidebar-item>
        </template>

    </vs-sidebar>
  </div>


</template>

<script>

export default {
  name: "SideBar",
  props: {
    parent: {
      type: String
    },
    sidebarLinks: {
      type: Array,
      required: true
    },
    index: {
      default: null,
      type: [String, Number]
    }
  },
  data: function(){
    return{
      doNotClose: false,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    //This is for mobile trigger
    isSidebarActive: {
      get() {
        return this.$store.state.isSidebarActive;
      },
      set(val) {
        this.$store.commit("IS_SIDEBAR_ACTIVE", val);
      }
    }
  },
  watch: {},
  methods: {
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebar();
    },
    setSidebar() {
      if (this.windowWidth < 1170) {
        this.$store.commit("IS_SIDEBAR_ACTIVE", false);
        this.doNotClose = false;
      } else {
        this.$store.commit("IS_SIDEBAR_ACTIVE", true);
        this.doNotClose = true;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebar();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
    this.setSidebar();
  }
};
</script>