<template>
  <div class="main-wrapper">
    <!---Navigation-->
    <Navbar
      :topbarColor="topbarColor"
      :logo="require('@/assets/images/logo.png')"
      :title="logotitle"
    />
    <!---Sidebar-->
    <SideBar parent=".main-wrapper" :sidebarLinks="sidebarLinks" />
    <!---Page Container-->
    <div class="main-container-fluid">
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import Navbar from "@/layout/full/header/Navbar.vue";
import SideBar from "@/layout/full/sidebar/SideBar.vue";
import sidebarLinks from "@/layout/full/sidebar/sidebarlinks.js";

export default {
  name: "MainContainer",
  components: {
    Navbar,
    SideBar
  },
  data: () => ({
    topbarColor: "#4F5467",
    logotitle: "",
    sidebarLinks: sidebarLinks
  }),
  methods: {
    notLogued: function(){
      if(!localStorage.getItem('adminuser')){
        this.$router.push('/login');
      }
    }
  },
  created: function(){
    this.notLogued();
  },
  watch:{
    $route (to, from){
      this.notLogued();
    }
  }
};
</script>

<style>
  .fade-leave-active {
    transition: all 0.1s ease-in-out;
  }
  .fade-enter-active {
    transition: all 1.5s ease-in-out;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>