<template>
  <header class="gridx">
    <vs-navbar
      v-model="indexActive"
      :color="topbarColor"
      class="topnavbar"
      text-color="rgba(255,255,255,0.7)"
      active-text-color="rgba(255,255,255,1)"
    >
      <!---
      Template logo
      -->
      <div slot="title" class="themelogo" style="min-width: 0px">
        <router-link to="/">
          <img :src="logo" v-if="logo" alt="Dashboard" style="max-width: 100px"/>
          <span class="logo-text" v-if="title">{{ title }}</span>
        </router-link>
      </div>
      <!---
      Mobile toggle
      -->
      <div slot="title">
        <div class="hiddenDesktop cursor-pointer" @click.stop="activeSidebar">
          <vs-icon icon="menu"></vs-icon>
        </div>
      </div>

      <vs-spacer></vs-spacer>
    </vs-navbar>
  </header>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    topbarColor: {
      type: String,
      default: "#4F5467"
    },
    title: {
      type: String
    },
    logo: {
      type: String
    }
  },
  data: () => ({
    indexActive: 0
  }),

  methods: {
    //This is for sidebar trigger in mobile
    activeSidebar() {
      this.$store.commit("IS_SIDEBAR_ACTIVE", true);
    }
  }
};
</script>
